import Cookies from "js-cookie";
import { useState } from "react";
import { FiLock } from "react-icons/fi";
import Button from "../components/Button";
import Form from "../components/Form";
import Input from "../components/Input";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confimNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState();

  const changePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confimNewPassword) {
      setErrors({ new_password2: "Passwords do not match." });
      return;
    }

    try {
      setLoading(true);
      setErrors({});
      setMessage(null);
      const body = {
        old_password: oldPassword,
        new_password1: newPassword,
        new_password2: confimNewPassword,
      };
      const response = await fetch(`/auth/password/change/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setMessage("Password changed successfully.");
        return;
      }
      const json = await response.json();

      setErrors(json);
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login">
        <Form
          title="Change Password"
          onSubmit={changePassword}
          message={message}
          error={errors.non_field_errors}
        >
          <Input
            label="Old Password"
            placeholder="Enter old password"
            type="password"
            required={true}
            icon={<FiLock />}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            error={errors.old_password}
          />
          <Input
            label="New Password"
            placeholder="Enter new password"
            type="password"
            required={true}
            icon={<FiLock />}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={errors.new_password1}
          />
          <Input
            label="Confirm New Password"
            placeholder="Enter new password again"
            type="password"
            required={true}
            icon={<FiLock />}
            value={confimNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            error={errors.new_password2}
          />
          <Button type="submit" loading={loading} disabled={loading}>
            Save Password
          </Button>
        </Form>
      </div>
    </div>
  );
}
