import { useEffect, useState } from "react";
import Select from "./Select";

export default function QuantitySelector({
  min_value,
  max_value,
  value,
  onChange,
  placeholder,
  ...props
}) {
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    const newChoices = Array(max_value)
      .fill()
      .slice(min_value, max_value)
      .map((_, value) => {
        return {
          value: value + 1,
          label: value + 1,
        };
      });
    setChoices(newChoices);
  }, [max_value, min_value]);

  return (
    <Select
      placeholder={placeholder}
      options={choices}
      value={value}
      onChange={onChange}
      classNamePrefix="select"
      selectClassName="checkout-select"
      clearable={false}
      searchable={false}
      {...props}
    />
  );
}
QuantitySelector.defaultProps = {
  min_value: 0,
  max_value: 10,
  placeholder: "Quantity",
};
