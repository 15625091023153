import { useCallback, useState } from "react";
import BaseTable from "./BaseTable";

import Cookies from "js-cookie";
import { useMemo } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { toast } from "react-toastify";
import { stripePromise } from "../settings";
import Button from "./Button";
import NonIdealState from "./NonIdealState";

const AccountsReveal = ({ accounts }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="accounts">
      {accounts ? (
        <>
          <div className="toggle-accounts" onClick={() => setOpen((prev) => !prev)}>
            {open ? <BiShow /> : <BiHide />}
          </div>
          {open && <div className="accounts-list">{accounts}</div>}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

const OrderAction = ({ order_id, triggerRefresh }) => {
  const [paying, setPaying] = useState();
  const [cancelling, setCancelling] = useState();

  const cancel = async () => {
    setCancelling(true);
    if (!window.confirm("Are you sure?")) return;
    try {
      let url = `/api/order/${order_id}/cancel/`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      if (response.ok) {
        toast.success(`Order ${order_id} cancelled successfully.`);
        triggerRefresh();
      } else {
        toast.error("Oops! Something went wrong.");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCancelling(false);
    }
  };

  const checkout = async () => {
    setPaying(true);

    try {
      let url = `/api/checkout/${order_id}/`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      if (response.ok) {
        const json = await response.json();
        if (json.stripe_session_id) {
          const stripe = await stripePromise;
          const error = await stripe.redirectToCheckout({ sessionId: json.stripe_session_id });
          console.log(error);
        }
      } else {
        const data = await response.json();
        toast.error(data.detail || "Oops! Something went wrong.");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPaying(false);
    }
  };

  return (
    <div className="actions">
      <Button
        kind="kind2"
        size="small"
        color="positive"
        loading={paying}
        disabled={paying || cancelling}
        onClick={checkout}
        fluid={true}
      >
        Pay
      </Button>
      <Button
        kind="kind2"
        size="small"
        color="negative"
        loading={cancelling}
        disabled={cancelling || paying}
        onClick={cancel}
        fluid={true}
      >
        Cancel
      </Button>
    </div>
  );
};

const filters = [
  {
    value: null,
    label: "All",
  },
  {
    value: "PAID",
    label: "Paid",
  },
  {
    value: "UNPAID",
    label: "Unpaid",
  },
  {
    value: "CANCELLED",
    label: "Cancelled",
  },
];

const TableFilter = ({ selected, onChange }) => {
  return (
    <div className="table-filters">
      {filters.map((item) => (
        <div
          className={`filter-item${selected === item.value ? " selected" : ""}`}
          onClick={() => {
            onChange(item.value);
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default function MyOrders() {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);

  const triggerRefresh = () => setRefresh((prev) => !prev);

  const fetchOrders = useCallback(async ({ signal, pageSize, pageIndex, filter }) => {
    try {
      setLoading(true);
      const response = await fetch(
        `/api/user/orders/?limit=${pageSize}&page=${pageIndex}&${filter ? filter : ""}`,
        {
          signal,
        }
      );
      if (response.ok) {
        const json = await response.json();
        setOrders(json.results);
        setPageCount(json.total_pages);
        setDataCount(json.count);
      } else {
        if (response.status === 404) {
          setOrders([]);
        }
        setError("Error fetching orders.");
      }
    } catch (reason) {
      if (reason.name === "AbortError") return;
      console.log(reason);
    } finally {
      setLoading(false);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date_created",
        width: 100,
        Cell: ({ value }) =>
          value
            ? new Date(value).toLocaleString(undefined, {
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                year: "numeric",
                month: "short",
              })
            : "-",
      },
      {
        Header: "Order Id",
        accessor: "order_id",
      },
      {
        Header: "Skin",
        accessor: "order_items[0]",
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        Cell: ({ value }) =>
          value
            ? `${value.quantity} x ${value.blue_essence / 1000}k+ BE - ${value.region} - ${
                value.skin
              }`
            : "",
      },
      {
        Header: "Accounts",
        accessor: "order_items[0].accounts",
        width: 210,
        minWidth: 210,
        maxWidth: 210,
        Cell: ({ value }) => <AccountsReveal accounts={value} />,
      },

      {
        Header: "Status",
        accessor: "status",
        width: 100,
        Cell: ({ value }) => (
          <div
            style={{ textAlign: "center", fontWeight: "bold" }}
            className={value === "PAID" ? "positive" : value === "UNPAID" ? "warning" : "negative"}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Actions",
        width: 100,
        Cell: ({ row }) =>
          row.original.status === "UNPAID" ? (
            <OrderAction order_id={row.original.order_id} triggerRefresh={triggerRefresh} />
          ) : (
            ""
          ),
      },
    ],
    []
  );

  return (
    <div className="my-orders">
      <TableFilter selected={statusFilter} onChange={setStatusFilter} />
      <BaseTable
        columns={columns}
        data={orders}
        fetchData={fetchOrders}
        loading={loading}
        pageCount={pageCount}
        pageSize={5}
        dataCount={dataCount}
        refresh={refresh}
        filter={{ status: statusFilter }}
        emptyState={
          <NonIdealState
            text="Nothing found."
            subtext={error || "All your purchases will be shown here"}
          />
        }
      />
    </div>
  );
}
