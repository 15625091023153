import { useState } from "react";

import { IoIosArrowDown } from "react-icons/io";
import { MdClear } from "react-icons/md";
import { default as ReactSelect, defaultTheme } from "react-select";

const { colors } = defaultTheme;

export default function Select({
  value,
  onChange,
  options,
  components,
  selectClassName,
  clearable,
  searchable,
  error,
  required,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const onSelectChange = (value) => {
    toggleOpen();
    onChange(value);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      value={value}
      onChange={onSelectChange}
      target={
        <div
          className={`select ${selectClassName || ""} ${error ? "error" : ""}`}
          onClick={toggleOpen}
        >
          <div className={"select-inner"}>
            {value ? `${value.label}` : props.placeholder}
            <IoIosArrowDown />
          </div>
          {!props.disabled && (
            <input
              autoComplete="off"
              style={{ opacity: 0, width: "100%", height: 0, position: "absolute" }}
              value={value}
              required={required}
            />
          )}
        </div>
      }
      clearable={clearable}
      searchable={searchable}
    >
      <ReactSelect
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null, ...components }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectChange}
        options={options}
        tabSelectsValue={false}
        value={value}
        {...props}
      />
    </Dropdown>
  );
}

// styled components

const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);

const Menu = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      className="menu"
      style={{
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        zIndex: 2,
      }}
      {...props}
    />
  );
};

const Dropdown = ({
  children,
  isOpen,
  target,
  value,
  onChange,
  onClose,
  clearable,
  searchable,
}) => (
  <div className={`dropdown ${!searchable ? "unsearchable" : ""}`}>
    {target}
    {isOpen ? (
      <Menu>
        {clearable && value && (
          <div className="clear" onClick={() => onChange(null)}>
            <MdClear size="2rem" />
            Clear Selection
          </div>
        )}
        {children}
      </Menu>
    ) : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

Select.defaultProps = {
  clearable: true,
  searchable: true,
};

Dropdown.defaultProps = {
  required: false,
  disabled: false,
};
