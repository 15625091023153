import { FaEuroSign, FaFire, FaGlobeAfrica, FaTheaterMasks } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LevelIcon } from "../assets/icons/level.svg";

const tiers = ["MYTHIC", "LEGENDARY", "EPIC", "ULTIMATE"];

export default function ProductItem({ product }) {
  const navigate = useNavigate();

  return (
    <div className="product card" onClick={() => navigate(`/product/${product.id}`)}>
      <img
        className="product-img"
        loading="lazy"
        src={product.image || process.env.PUBLIC_URL + "/static/default_champion.png"}
        alt="skin"
      />
      {tiers.includes(product.tier) && (
        <img
          className="tier-icon"
          src={process.env.PUBLIC_URL + `/static/${product.tier.toLowerCase()}.png`}
          alt="tier"
        />
      )}
      <div className="title">{product.name}</div>
      <div className="product-details">
        <div className="detail-item">
          <FaTheaterMasks className="icon" /> {product.tier.toLowerCase()} Skin Shard
        </div>
        <div className="detail-item">
          <FaGlobeAfrica className="icon" /> All Regions
        </div>
        {product.extra.be_range && (
          <div className="detail-item">
            <FaFire className="icon" /> {product.extra.be_range[0] / 1000}-
            {product.extra.be_range[1] / 1000}K BE
          </div>
        )}
        {product.extra.price_range && (
          <div className="detail-item">
            <FaEuroSign className="icon" />{" "}
            {product.extra.price_range[0].toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })}
            -
            {product.extra.price_range[1].toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })}
          </div>
        )}
        <div className="detail-item">
          <LevelIcon className="icon" />
          Level 30+
        </div>
      </div>
    </div>
  );
}
