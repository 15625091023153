import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";

const SidebarItem = ({ item, isActive, navigate }) => {
  return (
    <div
      className={`sidebar-item${isActive ? " active" : ""}`}
      onClick={() => (item.action ? item.action() : navigate(item.path))}
    >
      {item.icon}
      {item.value}
    </div>
  );
};

export default function MyAccountSidebar({ sidebarItems }) {
  const location = useLocation();
  const navigate = useRef(useNavigate());

  return (
    <div className="my-account-sidebar">
      <UserProfile />
      {sidebarItems.map((item) => (
        <SidebarItem
          item={item}
          isActive={location.pathname === item.path}
          navigate={navigate.current}
        />
      ))}
    </div>
  );
}
