import reviews from "../assets/json/reviews.json";
import ReviewItem from "./ReviewItem";

export default function Reviews() {
  return (
    <div className="reviews">
      {reviews.map((review, index) => (
        <ReviewItem key={index} review={review} />
      ))}
    </div>
  );
}
