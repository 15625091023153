import Cookies from "js-cookie";
import { useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import regions from "../assets/json/regions.json";
import { stripePromise } from "../settings";
import Button from "./Button";
import Form from "./Form";
import QuantitySelector from "./QuantitySelector";
import Select from "./Select";

const tiers = ["MYTHIC", "LEGENDARY", "EPIC", "ULTIMATE"];

export default function Checkout({
  product,
  region,
  blueEssence,
  quantity,
  onRegionChange,
  onBlueEssenceChange,
  onQuantityChange,
}) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const checkout = async (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);
    const body = {
      skin: product.id,
      blue_essence: blueEssence.value,
      region: region.value,
      quantity: quantity.value,
    };
    try {
      let url = "/api/checkout/";
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      if (response.ok) {
        const json = await response.json();
        if (json.stripe_session_id) {
          const stripe = await stripePromise;
          const error = await stripe.redirectToCheckout({ sessionId: json.stripe_session_id });
          console.log(error);
        }
      } else {
        if (response.status === 403) {
          setErrors({ detail: "You need to be logged in to checkout." });
        } else {
          const data = await response.json();
          setErrors(data || { detail: "Oops! Something went wrong." });
        }
      }
    } catch (err) {
      console.log(err);
      setErrors({ detail: "Oops! Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      className="checkout card"
      onSubmit={checkout}
      error={errors.non_field_errors || errors.detail}
    >
      <div className="tier">
        {tiers.includes(product.tier) && (
          <img
            className="icon"
            src={process.env.PUBLIC_URL + `/static/${product.tier.toLowerCase()}.png`}
            alt="tier"
          />
        )}
        {product.tier.toLowerCase()} Skin Shard
      </div>
      <Select
        placeholder="Select Region"
        options={regions}
        value={region}
        onChange={onRegionChange}
        classNamePrefix="select"
        selectClassName="checkout-select"
        clearable={false}
        searchable={false}
        required={true}
        error={errors.region}
      />
      <Select
        placeholder="Select BE"
        options={product.beChoices}
        value={blueEssence}
        onChange={onBlueEssenceChange}
        classNamePrefix="select"
        selectClassName="checkout-select"
        clearable={false}
        searchable={false}
        required={true}
        error={errors.blue_essence}
      />
      <QuantitySelector
        value={quantity}
        onChange={onQuantityChange}
        max_value={product?.extra?.quantity_limit}
        required
        error={errors.quantity}
      />
      <Button
        type="submit"
        className="purchase"
        loading={loading}
        disabled={loading}
        required={true}
        color="dark"
        fluid={true}
        icon={<FaLongArrowAltRight className="icon" />}
      >
        Purchase
      </Button>
    </Form>
  );
}
