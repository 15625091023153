import ScrollContainer from "react-indiana-drag-scroll";
import HomeTitle from "./HomeTitle";
import ProductItem from "./ProductItem";

export default function SimilarProducts({ products }) {
  return (
    <div className="products similar-products">
      <HomeTitle>Similar Products</HomeTitle>
      <ScrollContainer className="similar-product-items">
        {products.map((product) => (
          <ProductItem key={product.name} product={product} />
        ))}
      </ScrollContainer>
    </div>
  );
}
