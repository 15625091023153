import { FaDiscord, FaFacebookF, FaLongArrowAltRight, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { discordLink, g2gLink } from "../settings";
import Button from "./Button";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-inner container">
        <div className="sales">
          <div className="sales-text">
            Over <span>15000</span> sales on G2G
          </div>

          <Button
            className="navbar-item navbar-profile"
            icon={<FaLongArrowAltRight className="icon" />}
            onClick={() => window.open(g2gLink, "_blank")}
          >
            Visit profile
          </Button>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-nav">
          <div className="footer-socials">
            <div className="navbar-logo">
              <Link to={"/"}>
                <img
                  className="logo"
                  src={process.env.PUBLIC_URL + "/static/logo-light.png"}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="social-links">
              <a className="social-link" href={discordLink} target="_blank" rel="noreferrer">
                <FaFacebookF className="social-icon" />
              </a>
              <a className="social-link" href={discordLink} target="_blank" rel="noreferrer">
                <FaTwitter className="social-icon" />
              </a>
              <a className="social-link" href={discordLink} target="_blank" rel="noreferrer">
                <FaDiscord className="social-icon" />
              </a>
            </div>
          </div>
          <div className="footer-about">
            <div className="footer-title">About</div>
            <div className="footer-text">
              You can reach us anytime at support@lolaccshop.com. TurboSmurfs is a trading name of
              Andreu Amposta Boquera. Head Office in Gandesa, Pol·ligon Industrial La Plana, 33,
              T5, Spain. Company Number: 47861542Z Registered in Spain
            </div>
          </div>
          <div className="footer-links">
            <div className="footer-title">Links</div>
            <Link to="/#faq" className="footer-text">
              FAQ
            </Link>
            <Link to="/tos" className="footer-text">
              TOS
            </Link>
            <Link to="/privacy-policy" className="footer-text">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
