import { useEffect, useRef } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Faq from "../components/Faq";
import HomeTitle from "../components/HomeTitle";
import ProductsSearch from "../components/ProductsSearch";
import Reviews from "../components/Reviews";

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();

  const isMounted = useRef(false);

  useEffect(() => {
    console.log(isMounted);
    if (location.hash !== "") {
      const divId = location.hash.replace("#", "");
      const elem = document.getElementById(divId);
      setTimeout(
        function () {
          const yOffset = -100;
          const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        },
        isMounted.current ? 0 : 500
      );
    }
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, [location]);

  return (
    <div className="home">
      <div className="main-section">
        <div className="home-gradient">
          <video autoPlay muted loop id="myVideo">
            <source src={process.env.PUBLIC_URL + "/static/home.webm"} type="video/mp4" />
          </video>
        </div>
        <div className="banner container">
          <span className="title">
            Buy Cheap <br />
            League of Legends <br />
            Unranked Smurfs
          </span>
          <p className="description">
            Enjoy the cheapest premium accounts on the market. Our accounts boast an <br />
            incredibly low banrate of 2%~ and are MMR fresh so that you can start your <br />
            ranked journey without any problems.
          </p>
        </div>
      </div>
      <div className="info-section outer-container">
        <img
          className="info-img"
          src={process.env.PUBLIC_URL + "/static/gwen.png"}
          alt="gwen"
        ></img>
        <div className="info-details">
          <div className="info-details-inner">
            <HomeTitle>You're in good hands.</HomeTitle>
            <span className="description">
              We want to deliver the best and most superior service for our loyal customers. Buy
              your own smurf right now and enjoy our many benefits.
            </span>
          </div>

          <Button
            className="more"
            icon={<FaLongArrowAltRight className="arrow" />}
            onClick={() => navigate("#faq")}
          >
            Read FAQ
          </Button>
        </div>
      </div>
      <div className="product-section">
        <ProductsSearch />
      </div>
      <Faq />
      <div className="reviews-section">
        <Reviews />
      </div>
    </div>
  );
}
