import { cloneElement } from "react";
import Loader from "./Loader";

export default function Button({
  children,
  disabled,
  loading,
  className,
  icon,
  onClick,
  kind,
  size,
  color,
  fluid,
  ...props
}) {
  return (
    <button
      disabled={disabled}
      className={`button ${className || ""} ${color} ${kind} ${size} ${fluid ? "fluid" : ""}${
        loading ? " loading" : ""
      }${disabled ? " disabled" : ""}`}
      onClick={onClick}
      {...props}
    >
      {loading ? (
        <Loader size={size} />
      ) : (
        <>
          {children}
          {icon && cloneElement(icon, { className: "icon " + icon.props.className })}
        </>
      )}
    </button>
  );
}

Button.defaultProps = {
  kind: "kind1",
  size: "normal",
  color: "primary",
  fluid: false,
};
