import Cookies from "js-cookie";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import Form from "../components/Form";
import Input from "../components/Input";
import { captchaSiteKey } from "../settings";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState();

  const captchaRef = useRef();

  const login = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setErrors({});
      setMessage(null);
      const body = {
        email,
        captcha,
      };
      const response = await fetch(`/auth/password/reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify(body),
      });

      captchaRef.current?.reset();
      setCaptcha();
      if (response.status === 429) {
        setErrors({ email: "Too many attempts. Please slow down." });
        return;
      }
      if (response.ok) {
        setMessage("Password reset e-mail has been sent.");
      }
      const json = await response.json();
      setErrors(json);
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login">
        <Form
          title="Reset Password"
          onSubmit={login}
          message={message}
          error={errors.non_field_errors}
          extra={
            <div>
              <div>
                <Link to="/login">LOGIN</Link>
              </div>
              or
              <div>
                <Link to="/register">SIGNUP</Link>
              </div>
            </div>
          }
        >
          <Input
            label="Email"
            placeholder="Enter email"
            type="email"
            required={true}
            icon={<AiOutlineMail />}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email}
          />
          {captchaSiteKey && (
            <ReCAPTCHA
              className="recaptcha"
              ref={captchaRef}
              sitekey={captchaSiteKey}
              onChange={(value) => setCaptcha(value)}
            />
          )}
          <Button type="submit" loading={loading} disabled={loading}>
            Reset Password
          </Button>
        </Form>
      </div>
    </div>
  );
}
