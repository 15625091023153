export default function ReviewItem({ review }) {
  return (
    <div className="review-item">
      <div className="yl"></div>
      <div className="review-text">{review.text}</div>
      <div className="review-customer">
        <img src={process.env.PUBLIC_URL + review.icon} alt="icon" />
        <div className="customer-details">
          <div className="customer-name">{review.user}</div>
          <div className="customer-role">{review.role}</div>
        </div>
      </div>
    </div>
  );
}
