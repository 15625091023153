import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "./Loader";

export default function RedirectOnAuthFail({ to, children }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const setSearchParams = useSearchParams()[1];

  const fetchUser = useCallback(
    async (signal, to) => {
      try {
        setLoading(true);
        const response = await fetch("/auth/user/", { signal });

        if (response.ok) {
          setLoading(false);
          setSearchParams("");
        } else {
          navigate({
            pathname: to,
            search: location.search,
          });
        }
      } catch (reason) {
        console.log(reason);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchUser(controller.signal, to);
    return () => controller.abort();
  }, [fetchUser, to]);
  return loading ? <Loader /> : children;
}

RedirectOnAuthFail.defaultProps = {
  to: "/login",
};
