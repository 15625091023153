import Cookies from "js-cookie";
import { useCallback, useMemo } from "react";
import { BsGear, BsGrid } from "react-icons/bs";
import { RiLogoutCircleLine } from "react-icons/ri";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MyAccountSidebar from "../components/MyAccountSidebar";
import RedirectOnAuthFail from "../components/RedirectOnAuthFail";

export default function MyAccount() {
  const location = useLocation();
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      const response = await fetch("/auth/logout/", {
        method: "POST",
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      if (response.ok) {
        navigate("/");
      }
    } catch (error) {
      console.error();
    } finally {
    }
  }, [navigate]);

  const sidebarItems = useMemo(
    () => [
      {
        path: "/my-account/orders",
        icon: <BsGrid />,
        value: "Purchases",
      },
      {
        path: "/my-account/settings",
        icon: <BsGear />,
        value: "Settings",
      },
      {
        action: logout,
        icon: <RiLogoutCircleLine />,
        value: "Logout",
      },
    ],
    [logout]
  );

  const activeItem = sidebarItems.filter((item) => location.pathname === item.path);

  return (
    <RedirectOnAuthFail>
      <div className="my-account">
        <MyAccountSidebar sidebarItems={sidebarItems} />
        <div className="my-account-content">
          <div className="title">{activeItem[0] ? activeItem[0].value : ""}</div>
          <Outlet />
        </div>
      </div>
    </RedirectOnAuthFail>
  );
}
