import { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChangePassword from "./components/ChangePassword";
import Footer from "./components/Footer";
import MyOrders from "./components/MyOrders";
import Navbar from "./components/Navbar";
import RedirectOnAuthSuccess from "./components/RedirectOnAuthSuccess";
import ChampionContext from "./contexts/ChampionContext";
import ScrollToTop from "./hooks/scrollToTop";
import BuyProduct from "./pages/BuyProduct";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MyAccount from "./pages/MyAccount";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const [champions, setChampions] = useState([]);
  const [loadingChampions, setLoadingChampions] = useState(true);

  const fetchChampions = useCallback(async () => {
    try {
      setLoadingChampions(true);
      const response = await fetch("/api/champions/");
      if (!response.ok) {
        return;
      }
      const json = await response.json();
      setChampions(
        json.map((item) => ({
          id: item.id,
          label: item.name,
          value: item.name,
          image: item.image,
        }))
      );
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoadingChampions(false);
    }
  }, []);

  useEffect(() => {
    fetchChampions();
  }, [fetchChampions]);

  return (
    <div className="root-container">
      <Router>
        <ScrollToTop />
        <ChampionContext.Provider value={{ champions, loading: loadingChampions }}>
          <Navbar />
          <main className="contents">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/product/:productId" element={<BuyProduct />} />
              <Route path="/my-account" element={<MyAccount />}>
                <Route path="orders" element={<MyOrders />} />
                <Route path="settings" element={<ChangePassword />} />
              </Route>
              <Route
                path="/login"
                element={
                  <RedirectOnAuthSuccess>
                    <Login />
                  </RedirectOnAuthSuccess>
                }
              />
              <Route
                path="/register"
                element={
                  <RedirectOnAuthSuccess>
                    <Register />
                  </RedirectOnAuthSuccess>
                }
              />
              <Route
                path="/password/reset"
                element={
                  <RedirectOnAuthSuccess>
                    <ForgotPassword />
                  </RedirectOnAuthSuccess>
                }
              />
              <Route path="/password/reset/confirm/:uid/:token" element={<ResetPassword />} />
            </Routes>
          </main>
          <Footer />
        </ChampionContext.Provider>
      </Router>
      <ToastContainer
        className="toast"
        position="bottom-center"
        autoClose={2000}
        newestOnTop
        closeOnClick
        draggable
      />
    </div>
  );
}

export default App;
