import { useCallback, useEffect, useState } from "react";

export default function UserProfile() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const getUser = useCallback(async (signal) => {
    try {
      setLoading(true);
      const response = await fetch("/auth/user/", { signal });

      if (response.ok) {
        const json = await response.json();
        setUser(json);
        return;
      }
    } catch (reason) {
      if (reason.name === "AbortError") return;
      console.log(reason);
    } finally {
      setLoading();
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    getUser(controller.signal);
    return () => controller.abort();
  }, [getUser]);

  return (
    <div className="user-profile">
      <img
        className="profile-icon"
        src={process.env.PUBLIC_URL + `/static/profile-icon.jpg`}
        alt="icon"
      />
      {loading ? (
        "Loading..."
      ) : (
        <div className="user-info">
          <div className="username">{user.username}</div>
          <div className="joined">
            Joined{" "}
            {new Date(user.date_joined).toLocaleString(undefined, {
              year: "numeric",
              month: "short",
            })}
          </div>
        </div>
      )}
    </div>
  );
}
