import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import faq from "../assets/json/faq.json";
import HomeTitle from "./HomeTitle";

const FaqItem = ({ id, item, isOpen, onToggle }) => {
  return (
    <div className="faq-item">
      <div
        className="faq-title"
        onClick={(e) => {
          onToggle(isOpen ? null : id);
        }}
      >
        <span>{item.title}</span>
        <div className={`faq-icon ${isOpen ? "opened" : ""}`}>
          <IoIosArrowDown size="2rem" />
        </div>
      </div>
      <div className={`faq-content ${isOpen ? "opened" : ""}`}>{item.content}</div>
    </div>
  );
};

export default function Faq() {
  const [selected, setSelsected] = useState();

  return (
    <div id="faq" className="faq-section outer-container">
      <div className="faq-details">
        <HomeTitle>FAQ</HomeTitle>
        <div className="faq-items">
          {faq.map((item, index) => (
            <FaqItem
              key={index}
              id={index}
              item={item}
              isOpen={selected === index}
              onToggle={setSelsected}
            />
          ))}
        </div>
      </div>
      <img className="faq-img" src={process.env.PUBLIC_URL + "/static/faq.png"} alt="gwen"></img>
    </div>
  );
}
