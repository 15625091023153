import { useCallback, useEffect, useState } from "react";
import Button from "./Button";
import HomeTitle from "./HomeTitle";
import Loader from "./Loader";
import NonIdealState from "./NonIdealState";
import ProductItem from "./ProductItem";
import ProductsSearchForm from "./ProductSearchForm";

export default function ProductsSearch() {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  const fetchProducts = useCallback(async (query, page) => {
    try {
      setLoading(true);
      const response = await fetch(`/api/skins/?limit=9&page=${page}&${query}`);
      if (!response.ok) {
        setTotalPages(0);

        return;
      }
      const data = await response.json();
      setProducts((prev) => (page > 1 ? [...prev, ...data.results] : data.results));
      setTotalPages(data.total_pages);
    } catch (reason) {
      setTotalPages(0);
      console.log(reason);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSearchChange = useCallback(({ champion, rarity, role, lane }) => {
    const params = new URLSearchParams();
    if (champion) params.set("champion", champion.value);
    if (rarity) params.set("rarity", rarity.value);
    if (role) params.set("role", role.value);
    if (lane) params.set("lane", lane.value);
    setPage(1);
    setQuery(params.toString());
  }, []);

  const handleShowMore = () => {
    if (page < totalPages) setPage((prev) => prev + 1);
  };

  const renderProducts = () =>
    products.map((product) => <ProductItem key={product.name} product={product} />);

  useEffect(() => {
    fetchProducts(query, page);
  }, [query, page, fetchProducts]);

  return (
    <div id="products" className="products container">
      <HomeTitle>Find your next unranked smurf</HomeTitle>
      <ProductsSearchForm onSearchChange={onSearchChange} />
      <div className="products-items-container">
        <div className="product-items">
          {products.length > 0
            ? renderProducts()
            : !loading && (
                <NonIdealState
                  text="Nothin found."
                  subtext="Please try changing the search paramaters."
                />
              )}
        </div>
        <div className="products-load-more">
          {(totalPages === null || page < totalPages) &&
            (loading ? (
              <Loader />
            ) : (
              <Button kind="kind2" onClick={handleShowMore}>
                Show More
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
}
