import { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { createSearchParams, Link, useLocation, useNavigate } from "react-router-dom";
import { discordLink } from "../settings";

import Button from "./Button";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const [colorMode, setColorMode] = useState("dark");

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/register") setColorMode("light");
    else setColorMode("dark");
  }, [location]);

  return (
    <nav className={`navbar ${colorMode}`}>
      <div className="navbar-inner container">
        <div className="navbar-logo">
          <Link to={"/"}>
            <img
              className="logo"
              src={
                process.env.PUBLIC_URL +
                `/static/logo-${colorMode === "dark" ? "light" : "dark"}.png`
              }
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-links">
          <div className="navbar-item">
            <Link to={"/"}>Home</Link>
          </div>
          <div className="navbar-item">
            <Link to="/#products">Buy Skin Accounts</Link>
          </div>

          {discordLink && (
            <div className="navbar-item">
              <a href={discordLink} target="_blank" rel="noreferrer">
                Discord
              </a>
            </div>
          )}
          <div className="navbar-item">
            <Link to="/#faq">FAQ</Link>
          </div>
          <Button
            className="navbar-item navbar-profile"
            icon={<FaLongArrowAltRight className="icon" />}
            onClick={() => {
              const shouldNextParam = location.pathname.includes("product");
              navigate({
                pathname: `/my-account/orders`,
                ...(shouldNextParam && {
                  search: createSearchParams({ next: location.pathname }).toString(),
                }),
              });
            }}
          >
            My Account
          </Button>
        </div>
      </div>
    </nav>
  );
}
