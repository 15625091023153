import { FaCheckCircle } from "react-icons/fa";

export default function ProductDetail({ product, ...props }) {
  return (
    <div className="product-details">
      <img
        className="product-detail-image"
        src={product.image || process.env.PUBLIC_URL + "/static/default_champion.png"}
        alt={product.name}
      />
      <div className="product-description card">
        <div className="title">Description</div>
        <p className="text">
          At vero eos et iusto odio dignissimos ducimus, qui haec putat, ut ipsi auctori huius
          disciplinae placet: constituam, quid sit numeranda nec me ab illo inventore veritatis et
          expedita distinctio nam libero tempore, cum memoriter, tum etiam ac ratione. Si sine metu
          degendae praesidia firmissima filium morte multavit si sine causa? quae fuerit causa, mox
          videro; interea hoc tenebo, si ob rem voluptas assumenda est, quid sit extremum et inter
          mediocrem animadversionem atque natum sit, a natura incorrupte. Omne animal, simul atque
          in sanguinem suum tam inportuno tamque crudeli; sin, ut earum motus et accusamus et
          argumentandum et dolore suo sanciret militaris imperii disciplinam exercitumque in
          liberos atque haec ratio late patet in quo pertineant non possim.
        </p>
      </div>
      <div className="product-features card">
        <div className="title">Features</div>
        <div className="features">
          {props.region && (
            <div className="feature-item">
              <FaCheckCircle className="icon" />
              {props.region} Region
            </div>
          )}
          {props.blueEssence && (
            <div className="feature-item">
              <FaCheckCircle className="icon" />
              {props.blueEssence}
            </div>
          )}
          <div className="feature-item">
            <FaCheckCircle className="icon" />
            Level 30+
          </div>
          <div className="feature-item">
            <FaCheckCircle className="icon" />
            Unranked
          </div>
          <div className="feature-item">
            <FaCheckCircle className="icon" />
            5000 OE
          </div>
          <div className="feature-item">
            <FaCheckCircle className="icon" />
            Unverified Email
          </div>
        </div>
      </div>
    </div>
  );
}
