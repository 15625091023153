import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

export default function RedirectOnAuthSuccess({ to, children }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUser = useCallback(
    async (signal, to) => {
      try {
        setLoading(true);
        const response = await fetch("/auth/user/", { signal });
        if (response.ok) {
          navigate({
            pathname: to,
          });
        } else {
          setLoading(false);
        }
      } catch (reason) {
        console.log(reason);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchUser(controller.signal, to);
    return () => controller.abort();
  }, [fetchUser, to]);
  return loading ? <Loader /> : children;
}

RedirectOnAuthSuccess.defaultProps = {
  to: "/my-account/orders",
};
