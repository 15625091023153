import Cookies from "js-cookie";
import { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaSignInAlt } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Form from "../components/Form";
import Input from "../components/Input";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const loginStatus = async (queue_id) => {
    // Return whether to break or keep polling
    try {
      const response = await fetch(`/auth/login/status/${queue_id}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.user) {
          const urlParams = new URLSearchParams(location.search);
          navigate(urlParams.get("next") || "/my-account/orders");
          return false;
        }
        setErrors(data);
        return true;
      }
      const data = await response.json();
      setErrors(data);
      setLoading(false);
      return false;
    } catch (reason) {
      console.log(reason);
      setLoading(false);
    }
    return false;
  };

  const login = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setErrors({});
      const body = {
        username,
        password,
      };
      const response = await fetch(`/auth/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        const queueId = data.queue_id;
        if (!queueId) {
          setLoading(false);
          return;
        }
        while (true) {
          if (!(await loginStatus(queueId))) return;
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      }
      const json = await response.json();
      setErrors(json);
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login">
        <Form
          title="Login"
          subtitle="Welcome back!"
          onSubmit={login}
          error={errors.non_field_errors || errors.detail}
          extra={
            <div>
              <div>
                <Link to="/password/reset">Forgot Password?</Link>
              </div>
              <br />
              <div>
                Don't have an account ? <Link to="/register">Register</Link>.
              </div>
            </div>
          }
        >
          <Input
            label="Username"
            placeholder="Enter username"
            type="text"
            required={true}
            icon={<AiOutlineUser />}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={errors.username}
          />
          <Input
            label="Password"
            placeholder="Enter password"
            type="password"
            required={true}
            icon={<FiLock />}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errors.password}
          />
          <Button type="submit" loading={loading} disabled={loading} icon={<FaSignInAlt />}>
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
}
