import { useContext, useEffect, useState } from "react";
import lanes from "../assets/json/lanes.json";
import roles from "../assets/json/roles.json";
import tiers from "../assets/json/tiers.json";
import ChampionContext from "../contexts/ChampionContext";
import Select from "./Select";

const Championoption = ({ innerProps, isDisabled, data, ...props }) => {
  const relevantClasses = `select__option ${props.isSelected && "select__option--is-selected"} ${
    props.isFocused && "select__option--is-focused"
  }`;
  return !isDisabled ? (
    <div
      {...innerProps}
      className={relevantClasses}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "1.2rem",
        gap: "1.2rem",
      }}
    >
      <img
        alt="champion"
        style={{
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        }}
        src={data.image}
        loading="lazy"
      />{" "}
      {data.label}
    </div>
  ) : null;
};

export default function ProductsSearchForm({ onSearchChange }) {
  const [lane, setLane] = useState();
  const [champion, setChampion] = useState();
  const [role, setRole] = useState();
  const [rarity, setRarity] = useState();

  const { champions } = useContext(ChampionContext);

  useEffect(() => {
    onSearchChange({ champion, role, rarity, lane });
  }, [lane, champion, role, rarity, onSearchChange]);

  return (
    <div className="product-search-form">
      <Select
        placeholder="Champion"
        options={champions}
        value={champion}
        onChange={setChampion}
        classNamePrefix="select"
        components={{ Option: Championoption }}
      />
      <Select
        placeholder="Rarity"
        options={tiers}
        value={rarity}
        onChange={setRarity}
        classNamePrefix="select"
        searchable={false}
      />
      <Select
        placeholder="Role"
        options={roles}
        value={role}
        onChange={setRole}
        classNamePrefix="select"
        searchable={false}
      />
      <Select
        placeholder="Lane"
        options={lanes}
        value={lane}
        onChange={setLane}
        classNamePrefix="select"
        searchable={false}
      />
    </div>
  );
}
