import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Checkout from "../components/Checkout";
import Loader from "../components/Loader";
import ProductDetail from "../components/ProductDetail";
import SimilarProducts from "../components/SimilarProducts";

export default function BuyProduct() {
  const [product, setProduct] = useState();
  const [region, setRegion] = useState();
  const [blueEssence, setBlueEssence] = useState();
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState(0);

  const [loading, setLoading] = useState(false);

  const { productId } = useParams();

  const fetchProduct = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`/api/skin-product/${id}/`);
      if (!response.ok) {
        return;
      }
      const json = await response.json();

      setProduct({
        ...json,
        ...{
          beChoices: Object.keys(json.extra.price_mapping).map((be) => {
            return { label: `${be / 1000}k+ BE`, value: be };
          }),
        },
      });
    } catch (reason) {
      console.log(reason);
    } finally {
      setTimeout(() => setLoading(false), 500);
    }
  };

  useEffect(() => {
    fetchProduct(productId);
  }, [productId]);

  useEffect(() => {
    if (blueEssence && quantity) {
      var unitPrice = product.extra.price_mapping[blueEssence.value];
      if (product.extra.mythic_extra_price) unitPrice += product.extra.mythic_extra_price;
      const totalPrice = unitPrice * quantity.value;
      setPrice(totalPrice);
    }
  }, [blueEssence, product, quantity]);

  return (
    <div className="buy-product">
      {loading ? (
        <Loader />
      ) : (
        product && (
          <>
            <div className="product-heading-section">
              <div className="product-heading container">
                <div className="product-title">
                  <div className="product-name">{product.name} Account</div>
                  <div className="product-info">
                    {region && `${region.label} Region`} League of legends account containing{" "}
                    {product.name}
                    {blueEssence &&
                      ` and
                    ${blueEssence.label}`}
                  </div>
                </div>
                <div className="product-price-section">
                  <div className="product-price">
                    {price > 0 &&
                      price.toLocaleString(undefined, {
                        style: "currency",
                        currency: "EUR",
                      })}
                  </div>
                  {product.extra.mythic_extra_price && (
                    <div className="product-mythic">
                      {product.extra.mythic_extra_price.toLocaleString(undefined, {
                        style: "currency",
                        currency: "EUR",
                      })}{" "}
                      extra for mythic account
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="product-details-section container">
              <ProductDetail
                product={product}
                region={region?.label}
                blueEssence={blueEssence?.label}
              />
              <Checkout
                product={product}
                region={region}
                blueEssence={blueEssence}
                quantity={quantity}
                onRegionChange={setRegion}
                onBlueEssenceChange={setBlueEssence}
                onQuantityChange={setQuantity}
              />
            </div>
            <div className="similar-products-section">
              <SimilarProducts products={product.extra.similar_listings} />
            </div>
          </>
        )
      )}
    </div>
  );
}
