import { useEffect } from "react";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Button from "./Button";
import Loader from "./Loader";

export default function BaseTable({
  columns,
  data,
  loading,
  fetchData,
  pageCount: controlledPageCount,
  pageSize: controlledPageSize,
  dataCount,
  emptyState,
  refresh,
  filter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      pageCount: controlledPageCount,
      initialState: {
        pageSize: controlledPageSize,
      },
      manualGlobalFilter: true,
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    usePagination
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData({
      signal: controller.signal,
      pageIndex: pageIndex + 1,
      pageSize,
      filter: globalFilter,
    });
    return () => controller.abort();
  }, [fetchData, pageIndex, pageSize, globalFilter, refresh]);

  useEffect(
    () => {
      gotoPage(0); // Reset page on global filter change
      setGlobalFilter(
        new URLSearchParams(
          Object.fromEntries(Object.entries(filter).filter(([k, v]) => v))
        ).toString()
      );
    },
    // NOTE:  `filter`(dict) triggered useEffect falsely. So map every value of filter(dict) separetely to dependency array
    // eslint-disable-next-line
    [...Object.keys(filter).map((k) => filter[k])]
  );

  return loading ? (
    <Loader />
  ) : data.length > 0 ? (
    <div className="table-wrapper">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-info">
        Showing {pageIndex * pageSize + 1} to {pageIndex * pageSize + data.length} of {dataCount}
      </div>
      <div className="pagination">
        <Button
          size="small"
          className="pageItem"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FaAngleDoubleLeft />
        </Button>
        <Button
          size="small"
          className="pageItem"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft />
        </Button>

        <Button
          size="small"
          className="pageItem"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <FaAngleRight />
        </Button>
        <Button
          size="small"
          className="pageItem"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <FaAngleDoubleRight />
        </Button>
      </div>
    </div>
  ) : (
    emptyState
  );
}
